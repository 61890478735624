import React from 'react';
import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from "react";
import Select from 'react-select';
import { UserPermissionList } from '../../apidata/fetchapi';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import { confirm } from "react-confirm-box";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BackendRetailView = () => {

    let base64 = require('base-64');
    const aRef = useRef(null);
   
    const navigate = useNavigate();
    const [lead_id, setLeadId] = useState(base64.decode(useParams().id));
    const usr_token = localStorage.getItem('authToken');
    const user_id = localStorage.getItem('usrId');
    const usr_name = localStorage.getItem('name');

    const [listViewData, setListViewData] = React.useState([""]);
    const [usermessage, setMessage] = React.useState('');
    const getListViewData = async () => {
        const arr = [];
        const project_type = "RETAIL";
        try {
            fetch(ulmsUrl.concat('/backend/lead_details') , {
                method: 'POST',
                body: JSON.stringify({lead_id, usr_token, project_type}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response;
               
                setListViewData(result)
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getListViewData();
    }, []);

    console.log(listViewData);

    const [loading, setLoading] = useState(false);


   return (
    <>
     {/* {usermessage && <div className="alert alert-success alert-dismissible fade show" role="alert">
            <strong>{usermessage}</strong>    
        </div>} */}
    <ToastContainer />
    {loading && (
        <>
        <Loader/>
        </>
    ) }
    <div className="card" style={{overflowX:'auto'}}>
        <div className="card-body">
            <div className="card-header" id="combination">
                <h3 className="card-title">BACKEND LEAD DETAILS ( LEAD ID: {lead_id} )</h3>
                <div className='All_three_button'>
                <Link onClick={() => navigate(-1)}><strong><i title='back' className="fa fa-arrow-circle-left" style={{fontSize:'30px',color:'#0D4C7B',marginLeft: '10px'}}></i></strong></Link>
                </div>
            </div>
            <div className="invoice row">
                <div style={{float: 'left'}} className="table-scrollable col-md-6">
                    <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                                <th>LOB</th>
                                <td >{listViewData != '' && listViewData.data.project_type}</td>
                            </tr>
                            <tr>
                                <th>Product Type</th>
                                <td>{listViewData != '' && listViewData.data.products}</td>
                            </tr>
                            <tr>
                                <th>Service Type</th>
                                <td>{listViewData != '' && listViewData.data.service_type}</td>
                            </tr>
                            <tr>
                                <th>Product ID</th>
                                <td>{listViewData != '' && listViewData.data.project_id}</td>
                            </tr>
                           
                            <tr>
                                <th>Product Name</th>
                                <td>{listViewData != '' && listViewData.data.project_name}</td>
                            </tr>
                            <tr>
                                <th>Location Name</th>
                                <td>{listViewData != '' && listViewData.data.location_name}</td> 
                            </tr>
                            <tr>
                                <th>Zone Name</th>
                                <td>{listViewData != '' && listViewData.data.zone}</td> 
                            </tr> 
                            
                        </tbody>
                    </table>
                </div> 
                <div className="table-scrollable col-md-6">
                    <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                                <th>Category Name </th>
                                <td>{listViewData != '' && listViewData.data.categories_name}</td> 
                            </tr>
                            <tr>
                                <th> Area</th>
                                <td>{listViewData != '' && listViewData.data.area_sqft}</td> 
                            </tr>
                            <tr>
                                <th>Inventory Capacity (no of units)</th>
                                <td>{listViewData != '' && listViewData.data.inventory_capacity}</td>
                            </tr>
                            <tr>
                                <th>Unit Processing Capacity (no of units)</th>
                                <td >{listViewData != '' && listViewData.data.unit_processing_capacity}</td>
                            </tr>
                            <tr>
                                <th>Solution Name</th>
                                <td>{listViewData != '' && listViewData.data.solution_name}</td>
                            </tr>
                            <tr>
                                <th>Solution Details</th>
                                <td>{listViewData != '' && listViewData.data.solution_details}</td>
                            </tr>
                            <tr>
                                <th>Operation Type</th>
                                <td>{listViewData != '' && listViewData.data.operation_type}</td>
                            </tr>
                          
                        </tbody>
                    </table>
                </div> 
                <div style={{backgroundColor:'#46B653', fontWeight: 'bold', color:'white', borderRadius:'5px', marginTop:'15px'}}>
                    <div className='row'>
                        <div className="col-md-8" >
                            <h2 className="card-title" style={{fontSize: '20px', color:'white'}}>Inventory Details :</h2>
                        </div>
                        
                    </div>
                </div>
                <div style={{float: 'left'}} className="table-scrollable col-md-6">
                    <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                                <th>Operation Type</th>
                                <td>{listViewData != '' && listViewData.data.operation_type}</td>
                            </tr>
                            <tr>
                                <th>Nunber Of Racks</th>
                                <td >{listViewData != '' && listViewData.data.no_of_racks}</td>
                            </tr>
                            <tr>
                                <th>Nunber Of Shelves </th>
                                <td>{listViewData != '' && listViewData.data.no_of_shelves}</td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div> 
                <div className="table-scrollable col-md-6">
                    <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                                <th style={{width:'60%'}}>Shelves Height (in ft.) </th>
                                <td>{listViewData != '' && listViewData.data.shelves_height}</td> 
                            </tr>
                            <tr>
                                <th> Commodity</th>
                                <td>{listViewData != '' && listViewData.data.commodity}</td> 
                            </tr>
                            <tr>
                                <th>Storage Type </th>
                                <td>{listViewData != '' && listViewData.data.storage_type}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> 

                <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                    <table id="mainTable" className="table padded-table">
                    <thead>
                    </thead>
                        <tbody>                               
                            <tr>
                                <th>Rack Type</th>
                                <th>Rack Length</th>
                                <th>Rack Width</th>
                                <th>Rack Height</th>
                            </tr>
                            
                            {listViewData != '' && listViewData.data.rack_type.map((rackdata, index) => (  
                                <tr key={index}>  
                                    <td>{rackdata.rack_type.label}</td>  
                                    <td>{rackdata.rack_l}</td>  
                                    <td>{rackdata.rack_w}</td>  
                                    <td>{rackdata.rack_h}</td>
                                </tr>  
                            ))}  
                        </tbody>
                    </table>
                </div>

                <div style={{backgroundColor:'#46B653', fontWeight: 'bold', color:'white', borderRadius:'5px', marginTop:'15px'}}>
                    <div className='row'>
                        <div className="col-md-8" >
                            <h2 className="card-title" style={{fontSize: '20px', color:'white'}}>Handling Details :</h2>
                        </div>
                    </div>
                </div>
               
                <div style={{float: 'left'}} className="table-scrollable col-md-6">
                    <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                                <th>Type Of Picking</th>
                                <td>{listViewData != '' && listViewData.data.type_of_picking}</td>
                            </tr>
                            <tr>
                                <th>Picking Through</th>
                                <td >{listViewData != '' && listViewData.data.picking_through}</td>
                            </tr>
                            <tr>
                                <th>System Being Used </th>
                                <td>{listViewData != '' && listViewData.data.system_being_used}</td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div> 
                <div className="table-scrollable col-md-6">
                    <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                                <th >Remark For Product  </th>
                                <td>{listViewData != '' && listViewData.data.remark_for_product}</td> 
                            </tr>
                            <tr>
                                <th> Remark For Picture </th>
                                <td>{listViewData != '' && listViewData.data.remark_for_picture}</td> 
                            </tr>
                        </tbody>
                    </table>
                </div> 
                <div className="table-scrollable col-md-12">
                    <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                                <th>Lead Doc</th>
                                <td>
                                    {listViewData != '' && listViewData.imgData.map((data) => (  
                                    <>
                                        <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img} ( {data.type} )</span></Link>
                                    </>
                                    ))}  
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
             
            </div>
        </div>
    </div>
    </>
  )
}

export default BackendRetailView